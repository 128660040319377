<template>
  <v-row>
    <v-col>
      <mf-loading-dialog :loading="$apollo.loading">
        <create-first-step :class="step == 1 ? '' : 'd-none'" :account-id="accountId" @nextStep="nextStep" @cancel="cancel"></create-first-step>
        <create-second-step
          :class="step == 2 ? '' : 'd-none'"
          :account-id="accountId"
          :data-account="data_account"
          :units="units"
          @saveConnection="complete"
          @returnStep="returnStep"
        ></create-second-step>
      </mf-loading-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CreateFirstStep from './CreateFirstStep.vue'
import CreateSecondStep from './CreateSecondStep.vue'
import { MUTATION_CREATE_CONNECTION, MUTATION_CREATE_INTEGRATION } from '../../graphql/friday'
import { QUERY_GET_ACCOUNT, QUERY_GET_UNITS_BY_ACCOUNTS } from '@/modules/accounts/graphql'
import cnpj from '../../../../filters/cnpj'
export default {
  components: {
    CreateFirstStep,
    CreateSecondStep
  },
  data() {
    return {
      step: 1,
      data_first_step: null,
      data_second_step: null,
      accountId: '',
      data_account: null,
      force_push: false,
      units: []
    }
  },
  computed: {
    context() {
      return {
        uri: this.$microservicesUrls['friday'],
        headers: {
          authorization: localStorage.getItem('session_id'),
          accountId: this.accountId
        }
      }
    }
  },
  apollo: {
    getUnitsByAccounts: {
      query: QUERY_GET_UNITS_BY_ACCOUNTS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          accounts: this.accountId
        }
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update({ getUnitsByAccounts }) {
        this.units = getUnitsByAccounts.map(item => {
          return {
            value: item.ecommerce_id,
            formated_name: `[${item.ecommerce_id}]|${item.name}|${cnpj(item.cnpj)}`
          }
        })
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      result({ data }) {
        this.data_account = {
          db_name: data.adminAccount.db_name,
          cnpj: data.adminAccount.cnpj
        }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    nextStep(item) {
      this.step = 2
      this.data_first_step = item
    },
    getConfigCreateConnection() {
      const variables = {
        machine_id: this.data_first_step.machine_id,
        instance_id: this.data_first_step.instance_id,
        account_id: this.accountId
      }
      return {
        mutation: MUTATION_CREATE_CONNECTION,
        variables,
        context: this.context
      }
    },
    getConfigCreateIntegrations(data_second_step) {
      let integrators = data_second_step.map(item => {
        const config = item.executor === 'dbsync' ? { ...item.config, port: item.config.port.toString() } : { ...item.config }
        return {
          job_id: item.job_id,
          executor: item.executor,
          config,
          cron_schedule: item.cron_schedule,
          machine_id: this.data_first_step.machine_id,
          type: item.type
        }
      })
      const variables = {
        data: { integrators }
      }
      return {
        mutation: MUTATION_CREATE_INTEGRATION,
        variables,
        context: this.context
      }
    },
    async complete(item) {
      try {
        await this.$apollo.mutate(this.getConfigCreateConnection()).then(async () => {
          await this.$apollo.mutate(this.getConfigCreateIntegrations(item)).then(() => {
            this.$alert({
              alert_message: 'Conexão salva com sucesso!',
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
            this.force_push = true
            this.$router.push(`/accounts/${this.accountId}/friday`)
          })
        })
      } catch (err) {
        console.log(err)
        return this.$alert({
          alert_message: 'Falha salvar ',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    returnStep() {
      this.step = 1
    },
    cancel() {
      this.$router.push(`/accounts/${this.accountId}/friday`)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.force_push) {
      this.$confirm({
        title: 'Tem certeza?',
        message: 'Sua conexão ainda não foi salva e todas as suas alterações recentes serão perdidas ao sair dessa página.',
        confirmText: 'FICAR E CONTINUAR CRIAÇÃO',
        cancelText: 'SAIR DA TELA'
      })
        .then(() => {
          next(false)
        })
        .catch(() => {
          next()
        })
    } else {
      next()
    }
  }
}
</script>

<style></style>
